import type { Library } from "../Library/Library.model";
import {
  deserialiseDate,
  type DeserialiseDates,
  type SerialisedDate,
} from "../utils/Serialise.model";
import type { Metadata } from "./Case.model";
import {
  getRiskSummaryFromScreenResult,
  type RiskSummaryViewModel,
} from "./CaseRisk.model";
import type { BackendBaseHistoryEntry } from "./History.model";

export type RuleHitSummary = {
  id: string;
  name: string;
  condition: string;
};

export type MatchViewModel = {
  keyword: string;
  field: string;
  text: string;
  position: number;
  length: number;
  extendedText: string;
};

export type HitViewModel = {
  library: Library;
  rule: RuleHitSummary;
  metadata: Metadata;
  matches: MatchViewModel[];
  extendedText: string;
};

export type CorrelationData = { [key: string]: string[] };

export type BackendScreenResultViewModel = {
  screeningId: string;
  screeningDate: SerialisedDate;
  lastModified: SerialisedDate;
  ingestionDate: SerialisedDate;
  correlationData: CorrelationData;
  hits: HitViewModel[];
};
export type BffScreenResultViewModel = BackendScreenResultViewModel &
  BackendBaseHistoryEntry & {
    riskSummary: RiskSummaryViewModel;
    type: "screenresult";
  };
export type ScreenResultViewModel = DeserialiseDates<BffScreenResultViewModel>;

export function mapScreenResult(
  screen: BackendScreenResultViewModel
): BffScreenResultViewModel {
  const ret = screen as any as BffScreenResultViewModel;
  ret.createdAt = screen.screeningDate;
  ret.ingestionDate = screen.ingestionDate;
  ret.riskSummary = getRiskSummaryFromScreenResult(screen);
  ret.type = "screenresult";
  return ret;
}

export function deserialiseScreenResult(
  screen: BffScreenResultViewModel
): ScreenResultViewModel {
  return {
    ...screen,
    createdAt: deserialiseDate(screen.createdAt),
    screeningDate: deserialiseDate(screen.screeningDate),
    lastModified: deserialiseDate(screen.lastModified),
    ingestionDate: deserialiseDate(screen.ingestionDate),
  } satisfies ScreenResultViewModel;
}
