import { z } from "zod";
import {
  queryFilterSchema,
  type FilterBody,
  type FrontendQueryFilter,
  type QueryFilter,
} from "~/src/models/utils/Filter.model";
import type { SerialisedDate } from "./Serialise.model";

export type HalPage<T, K extends string> = {
  page: PageMeta;
  _links: HalLinks;
  _embedded: Embedded<T, K>;
};
export type Embedded<T, TKey extends string> = {
  [key in TKey]: T[];
};

export type PageMeta = {
  number: number;
  size: number;
  totalElements: number;
};

export type HalLinks = {
  self: HalLink;
  first: HalLink;
  next: HalLink;
  last: HalLink;
};

export type HalLink = {
  href: string;
  method: string;
};

export type APIPagedResponse<T> = {
  page: { number: number; size: number; totalElements: number };
  data: T[];
};

export type RequestObject<
  TFilter extends {
    field: string;
    value: (string | number | boolean)[];
    operator: string;
  } = QueryFilter,
  KMeta = Record<string, any>,
> = {
  page: number;
  pageSize: number;
  sortBy?: string;
  search?: string;
  sortOrder?: SortOrder;
  filters?: TFilter[];
  meta?: KMeta;
};
export type FrontendRequestObject<KMeta = Record<string, any>> = RequestObject<
  FrontendQueryFilter,
  KMeta
>;

export type SortOrder = "asc" | "desc";
export type SortModel = {
  [key: string]: SortOrder;
};

export type ListRequestModel = {
  page?: number;
  size?: number;
  sort?: SortModel;
  filter?: FilterBody;
};

export const idRouteSchema = z.object({ id: z.string().transform(decodeURI) });

export const defaultListBodySchema = z.object({
  page: z.number(),
  pageSize: z.number().optional(),
  sortBy: z.string().optional().nullable(),
  sortOrder: z.union([z.literal("asc"), z.literal("desc")]).optional(),
  filters: z.array(queryFilterSchema).optional(),
});
export type DefaultListBody = z.infer<typeof defaultListBodySchema>;

export const unfuckDueDate = (
  d: SerialisedDate | string | null | undefined
): SerialisedDate | null => {
  if (d === "0001-01-01T00:00:00" || !d) return null;

  return d as SerialisedDate;
};
